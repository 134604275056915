export const iconsInatu = {
    açai: require('../assets/icons_novos/açaí.svg').default,
    andiroba: require('../assets/icons_novos/andiroba.svg').default,
    breu: require('../assets/icons_novos/breu.svg').default,
    buriti: require('../assets/icons_novos/buriti.svg').default,
    cacau: require('../assets/icons_novos/cacau.svg').default,
    cafe_verde: require('../assets/icons_novos/café verde.svg').default,
    castanha: require('../assets/icons_novos/castanha.svg').default,
    copaiba: require('../assets/icons_novos/copaíba.svg').default,
    cacau: require('../assets/icons_novos/cacau.svg').default,
    citronela: require('../assets/icons_novos/citronela.svg').default,
    cumaru: require('../assets/icons_novos/cumaru.svg').default,
    cupuaçu: require('../assets/icons_novos/cupuaçu.svg').default,
    latex: require('../assets/icons_novos/látex.svg').default,
    murumuru: require('../assets/icons_novos/murumuru.svg').default,
    pataua: require('../assets/icons_novos/pataua.svg').default,
    pau_rosa: require('../assets/icons_novos/pau rosa.svg').default,
    pimenta_de_macaco: require('../assets/icons_novos/pimenta de macaco.svg').default,
    pitanga: require('../assets/icons_novos/pitanga.svg').default,
    priprioca: require('../assets/icons_novos/priprioca.svg').default,
    sangue_de_dragão: require('../assets/icons_novos/sangue de dragão.svg').default,
    tucuma_amendoa: require('../assets/icons_novos/tucumã amêndoa.svg').default,
    tucuma_semente: require('../assets/icons_novos/tucumã semente.svg').default,
    ucuuba: require('../assets/icons_novos/ucuuba.svg').default
};

export const iconsInatuPublic = {
    açai: require('../assets/iconsPublic/acai.svg').default,
    andiroba: require('../assets/iconsPublic/andiroba.svg').default,
    breu: require('../assets/iconsPublic/breu.svg').default,
    buriti: require('../assets/iconsPublic/buriti.svg').default,
    cacau: require('../assets/iconsPublic/cacau.svg').default,
    cafe_verde: require('../assets/iconsPublic/cafe.svg').default,
    castanha: require('../assets/iconsPublic/castanha.svg').default,
    copaiba: require('../assets/iconsPublic/copaiba.svg').default,
    cacau: require('../assets/iconsPublic/cacau.svg').default,
    citronela: require('../assets/iconsPublic/citronela.svg').default,
    cumaru: require('../assets/iconsPublic/cumaru.svg').default,
    cupuaçu: require('../assets/iconsPublic/cupuaçu.svg').default,
    latex: require('../assets/iconsPublic/latex.svg').default,
    murumuru: require('../assets/iconsPublic/murumuru.svg').default,
    pataua: require('../assets/iconsPublic/pataua.svg').default,
    pau_rosa: require('../assets/iconsPublic/pau_rosa.svg').default,
    pimenta_de_macaco: require('../assets/iconsPublic/pimenta_de_macaco.svg').default,
    pitanga: require('../assets/iconsPublic/pitanga.svg').default,
    
    sangue_de_dragão: require('../assets/iconsPublic/sangue_de_dragao.svg').default,
    tucuma_amendoa: require('../assets/iconsPublic/tucuma_amendoa.svg').default,
    tucuma_semente: require('../assets/iconsPublic/tucuma.svg').default,
    ucuuba: require('../assets/iconsPublic/ucuuba.svg').default
};

export const materiasPrimasNomeImg = [
    { nomeA: 'açai', nomeB: 'açaí', nomeDataBase: 'açai'},
    { nomeA: 'andiroba', nomeB: 'andiroba', nomeDataBase: 'andiroba'},
    { nomeA: 'breu', nomeB: 'breu', nomeDataBase: 'breu'},
    { nomeA: 'buriti', nomeB: 'buriti', nomeDataBase: 'buriti'},
    { nomeA: 'cacau', nomeB: 'cacau', nomeDataBase: 'cacau'},
    { nomeA: 'cafe_verde', nomeB: 'café verde', nomeDataBase: 'cafe verde'},
    { nomeA: 'castanha', nomeB: 'castanha', nomeDataBase: 'castanha'},
    { nomeA: 'copaiba', nomeB: 'copaíba', nomeDataBase: 'copaiba'},
    { nomeA: 'citronela', nomeB: 'citronela', nomeDataBase: 'citronela'},
    { nomeA: 'cumaru', nomeB: 'cumaru', nomeDataBase: 'cumaru'},
    { nomeA: 'cupuaçu', nomeB: 'cupuaçu', nomeDataBase: 'cupuaçu'},
    { nomeA: 'latex', nomeB: 'látex', nomeDataBase: 'latex'},
    { nomeA: 'murumuru', nomeB: 'murumuru', nomeDataBase: 'murumuru'},
    { nomeA: 'pataua', nomeB: 'pataua', nomeDataBase: 'pataua'},
    { nomeA: 'pau_rosa', nomeB: 'pau rosa', nomeDataBase: 'pau rosa'},
    { nomeA: 'pimenta_de_macaco', nomeB: 'pimenta de macaco', nomeDataBase: 'pimenta de macaco'},
    { nomeA: 'pitanga', nomeB: 'pitanga', nomeDataBase: 'pitanga'},
    { nomeA: 'priprioca', nomeB: 'priprioca', nomeDataBase: 'priprioca'},
    { nomeA: 'sangue_de_dragão', nomeB: 'sangue de dragão', nomeDataBase: 'sangue de dragao'},
    { nomeA: 'tucuma_amendoa', nomeB: 'tucumã amêndoa', nomeDataBase: 'tucuma amendoa'},
    { nomeA: 'tucuma_semente', nomeB: 'tucumã semente', nomeDataBase: 'tucuma semente'},
    { nomeA: 'ucuuba', nomeB: 'ucuuba', nomeDataBase: 'ucuuba'},
];


export const materiasPrimas = ['açaí', 'andiroba', 'breu', 'buriti', 'cacau', 'café verde', 'castanha', 'copaíba', 'cacau',
    'citronela', 'cumaru', 'cupuaçu', 'látex', 'murumuru', 'pataua', 'pau rosa', 'pimenta de macaco', 'pitanga', 'priprioca', 'sangue de dragão', 'tucumã amêndoa',
    'tucumã semente', 'ucuuba'];